// @import "react-modern-drawer/dist/index.css";
@import "rc-drawer/assets/index.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./select.scss";

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Sora", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  overflow-x: hidden;
  color: theme("colors.clever-black");
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

#__next {
  overflow: hidden;
  position: relative;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

// ul,
// li {
//   list-style: none;
//   margin: 0;
//   padding: 0;
// }

strong {
  font-weight: 500 !important;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

::-webkit-scrollbar {
  // -webkit-appearance: none;
  height: 0 !important;
  width: 0 !important;
}

.one-line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.hs-fieldtype-text {
  .input {
    height: 56px;
    margin-right: 0 !important;
    padding: 0 !important;
    @apply rounded-full border-none;
  }
}

.hs-fieldtype-textarea {
  .input {
    padding: 0 !important;
    margin-right: 0 !important;
    height: 112px;
    @apply rounded-[32px] border-none;
  }
}

.two-line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.three-line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.four-line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.five-line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.disabled-link {
  pointer-events: none !important;
}

.collapse {
  visibility: visible !important;
}

.dropdown-icon {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.big-logo-container {
  width: 200px;
  height: 42px;
}

.logo-image {
  background: url("/Assets/Svg/clever-logo.svg") no-repeat center;
  background-size: contain;
  width: 100%;
  height: 100%;
}

.black-logo-image {
  background: url("/Assets/Svg/black-clever-logo.svg") no-repeat center;
  background-size: contain;
  width: 100%;
  height: 100%;
}

.small-logo-container {
  width: 152px;
  height: 32px;
}

.drawer-side .collapse-title,
:where(.collapse > input[type="checkbox"]) {
  min-height: auto;
}

// nprogress
#nprogress .bar {
  background: black !important;
}

#nprogress .peg {
  box-shadow:
    0 0 10px black,
    0 0 5px black;
}

#nprogress .spinner-icon {
  border-top-color: black;
  border-left-color: black;
}

iframe#widget2 {
  width: 100vh;
  height: calc(100vh / 1.75);
}

.menu :where(li:not(.menu-title):not(:empty)) > :where(:not(ul).active),
.menu :where(li:not(.menu-title):not(:empty)) > :where(*:not(ul):active) {
  background-color: transparent !important;
}

.menu :where(li:not(.menu-title):not(:empty)) > :where(*:not(ul):focus),
.menu :where(li:not(.menu-title):not(:empty)) > :where(*:not(ul):hover) {
  background-color: transparent !important;
}
